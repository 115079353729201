// import React, { useEffect, useState } from 'react';
// import secureLocalStorage from 'react-secure-storage';

// const ReportList = () => {
//     const [reports, setReports] = useState([]);
//     const [filterDate, setFilterDate] = useState('');

//     useEffect(() => {
//         const yourAuthToken = secureLocalStorage.getItem('token'); // Replace 'yourAuthToken' with the actual key

//         fetch(`https://api.eth-prosperity.com/api/reportpdf/?date_sent=${filterDate}`, {
//             headers: {
//                 'Authorization': `Token ${yourAuthToken}`, // Use Token instead of Bearer
//             },
//         })
//         .then(response => response.json())
//         .then(data => setReports(data))
//         .catch(error => console.error('Error fetching reports:', error));
//     }, [filterDate]);

//     return (
//         <div>
//             <h1 className="text-2xl font-bold mb-4 p-3">Content Reports</h1>
//             <table className="min-w-full bg-white border border-gray-200">
//                 <thead>
//                     <tr>
//                         <th className="px-4 py-2">ID</th>
//                         <th className="px-4 py-2">User</th>
//                         <th className="px-4 py-2">Date Sent</th>
//                         <th className="px-4 py-2">PDF File</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {reports.map(report => (
//                         <tr key={report.id}>
//                             <td className="border px-4 py-2">{report.id}</td>
//                             <td className="border px-4 py-2">{report.user}</td>
//                             <td className="border px-4 py-2">{report.date_sent}</td>
//                             <td className="border px-4 py-2">
//                                 <a href={report.pdf_file} target="_blank" rel="noopener noreferrer">
//                                     View PDF
//                                 </a>
//                             </td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     );
// };

// export default ReportList;


import React, { useEffect, useState } from 'react';
import secureLocalStorage from 'react-secure-storage';

const ReportList = () => {
    const [reports, setReports] = useState([]);
    const [filterDate, setFilterDate] = useState('');

    useEffect(() => {
        const yourAuthToken = secureLocalStorage.getItem('token'); // Replace 'yourAuthToken' with the actual key

        // Fetch reports based on the selected date filter
        fetch(`https://api.eth-prosperity.com/api/reportpdf/?date_sent=${filterDate}`, {
            headers: {
                'Authorization': `Token ${yourAuthToken}`, // Use Token instead of Bearer
            },
        })
        .then(response => response.json())
        .then(data => setReports(data))
        .catch(error => console.error('Error fetching reports:', error));
    }, [filterDate]); // Trigger the useEffect whenever filterDate changes

    return (
        <div>
            <h1 className="text-2xl font-bold mb-4 p-3">Content Reports</h1>
            
            {/* Date Filter Input */}
            <div className="mb-4">
                <label htmlFor="dateFilter" className="mr-2">Filter by Date:</label>
                <input
                    type="date"
                    id="dateFilter"
                    value={filterDate}
                    onChange={(e) => setFilterDate(e.target.value)}
                    className="border px-2 py-1"
                />
            </div>

            {/* Reports Table */}
            <table className="min-w-full bg-white border border-gray-200">
                <thead>
                    <tr>
                        <th className="px-4 py-2">ID</th>
                        <th className="px-4 py-2">User</th>
                        <th className="px-4 py-2">Date Sent</th>
                        <th className="px-4 py-2">PDF File</th>
                    </tr>
                </thead>
                <tbody>
                    {reports.map(report => (
                        <tr key={report.id}>
                            <td className="border px-4 py-2">{report.id}</td>
                            <td className="border px-4 py-2">{report.user}</td>
                            <td className="border px-4 py-2">{report.date_sent}</td>
                            <td className="border px-4 py-2">
                                <a href={report.pdf_file} target="_blank" rel="noopener noreferrer">
                                    View PDF
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ReportList;
