// import * as React from 'react';
// import { BarChart } from '@mui/x-charts/BarChart';

// const seriesA = {
//   data: [2, 3, 1, 4, 5],
//   label: 'Series A',
// };
// const seriesB = {
//   data: [3, 1, 4, 2, 1],
//   label: 'Series B',
// };
// const seriesC = {
//   data: [3, 2, 4, 5, 1],
//   label: 'Series C',
// };
// const seriesD = {
//   data: [3, 2, 4, 5, 1],
//   label: 'Series D',
// };
// export default function BasicStacking() {
//   return (
//     <BarChart
//       width={600}
//       height={300}
//       series={[
//         { ...seriesA, stack: 'total' },
//         { ...seriesB, stack: 'total' },
//         { ...seriesC, stack: 'total' },
//         { ...seriesD, stack: 'total' },
//       ]}
//     />
//   );
// }


// import React, { useEffect, useState } from 'react';
// import { BarChart } from '@mui/x-charts/BarChart';

// export default function BasicStacking() {
//   const [chartData, setChartData] = useState({
//     postReach: [],
//     numberOfShares: [],
//     numberOfLikes: [],
//     categories: [],
//   });
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     fetch('http://127.0.0.1:8000/api/api/chart-data/')
//       .then(response => response.json())
//       .then(data => {
//         setChartData(data);
//         setLoading(false);
//       });
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   const postReach = {
//     data: chartData.postReach,
//     label: 'Post Reach',
//   };
//   const numberOfShares = {
//     data: chartData.numberOfShares,
//     label: 'Number of Shares',
//   };
//   const numberOfLikes = {
//     data: chartData.numberOfLikes,
//     label: 'Number of Likes',
//   };

//   return (
//     <BarChart
//       width={600}
//       height={300}
//       series={[
//         { ...postReach, stack: 'total' },
//         { ...numberOfShares, stack: 'total' },
//         { ...numberOfLikes, stack: 'total' },
//       ]}
//       xAxis={{
//         labels: chartData.categories,
//       }}
//     />
//   );
// }



import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

export default function BasicStacking() {
  const [chartData, setChartData] = useState({
    postReach: [],
    numberOfShares: [],
    numberOfLikes: [],
    categories: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('http://127.0.0.1:8000/api/api/chart-data/')
      .then(response => response.json())
      .then(data => {
        setChartData(data);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const postReach = {
    data: chartData.postReach,
    label: 'Post Reach',
  };
  const numberOfShares = {
    data: chartData.numberOfShares,
    label: 'Number of Shares',
  };
  const numberOfLikes = {
    data: chartData.numberOfLikes,
    label: 'Number of Likes',
  };

  return (
    <BarChart
      width={600}
      height={300}
      series={[
        { ...postReach, stack: 'total' },
        { ...numberOfShares, stack: 'total' },
        { ...numberOfLikes, stack: 'total' },
      ]}
      xAxis={{
        categories: chartData.categories,
      }}
    />
  );
}
