// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';

// // // Tailwind CSS styles are applied directly within the JSX
// // const WeeklyReportWithFetch = () => {
// //   const [reports, setReports] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [error, setError] = useState(null);

// //   useEffect(() => {
// //     // Fetch data from the API
// //     axios.get('http://127.0.0.1:8000/api/weekly-report-agenda/')
// //       .then(response => {
// //         setReports(response.data.reports);
// //         setLoading(false);
// //       })
// //       .catch(error => {
// //         setError('Error fetching reports.');
// //         setLoading(false);
// //       });
// //   }, []);

// //   if (loading) {
// //     return <div className="text-center py-4">Loading...</div>;
// //   }

// //   if (error) {
// //     return <div className="text-center py-4 text-red-500">{error}</div>;
// //   }

// //   return (
// //     <div className="p-6 bg-white shadow-md rounded-lg">
// //       <h1 className="text-2xl font-bold mb-4">Weekly Reports</h1>
// //       <div className="space-y-4">
// //         {reports.length > 0 ? (
// //           reports.map((report, index) => (
// //             <div
// //               key={index}
// //               className="border border-gray-200 p-4 rounded-lg bg-gray-50"
// //             >
// //               <h2 className="text-xl font-semibold mb-2">Report by {report.user}</h2>
// //               <div className="grid grid-cols-7 gap-4">
// //                 <div className="col-span-1 font-medium">Monday:</div>
// //                 <div className="col-span-6">{report.monday || 'No report'}</div>

// //                 <div className="col-span-1 font-medium">Tuesday:</div>
// //                 <div className="col-span-6">{report.tuesday || 'No report'}</div>

// //                 <div className="col-span-1 font-medium">Wednesday:</div>
// //                 <div className="col-span-6">{report.wednesday || 'No report'}</div>

// //                 <div className="col-span-1 font-medium">Thursday:</div>
// //                 <div className="col-span-6">{report.thursday || 'No report'}</div>

// //                 <div className="col-span-1 font-medium">Friday:</div>
// //                 <div className="col-span-6">{report.friday || 'No report'}</div>

// //                 <div className="col-span-1 font-medium">Saturday:</div>
// //                 <div className="col-span-6">{report.saturday || 'No report'}</div>

// //                 <div className="col-span-1 font-medium">Sunday:</div>
// //                 <div className="col-span-6">{report.sunday || 'No report'}</div>

// //                 <div className="col-span-1 font-medium">Date:</div>
// //                 <div className="col-span-6">{report.created_at}</div>
// //               </div>
// //             </div>
// //           ))
// //         ) : (
// //           <div className="text-center py-4">No reports available</div>
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default WeeklyReportWithFetch;


// import React, { useState, useEffect } from 'react';
// // import secureLocalStorage from 'secure-localstorage'; // Import secure-localstorage
// import secureLocalStorage from 'react-secure-storage';

// const WeeklyReportWithFetch = () => {
//   const [reports, setReports] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchReports = async () => {
//       try {
//         const apiUrl = 'http://127.0.0.1:8000/api/weekly-report-agenda/';
//         const token = secureLocalStorage.getItem('token'); // Retrieve the token from secure storage

//         const response = await fetch(apiUrl, {
//           headers: {
//             Authorization: `Token ${token}`, // Use Bearer token
//           },
//         });

//         if (!response.ok) {
//           throw new Error('Failed to fetch reports');
//         }

//         const data = await response.json();
//         setReports(data.reports);
//       } catch (error) {
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchReports();
//   }, []);

//   if (loading) {
//     return <div className="text-center py-4">Loading...</div>;
//   }

//   if (error) {
//     return <div className="text-center py-4 text-red-500">{error}</div>;
//   }

//   return (
//     <div className="p-6 bg-white shadow-md rounded-lg">
//       <h1 className="text-2xl font-bold mb-4">Weekly Reports</h1>
//       <div className="space-y-4">
//         {reports.length > 0 ? (
//           reports.map((report, index) => (
//             <div
//               key={index}
//               className="border border-gray-200 p-4 rounded-lg bg-gray-50"
//             >
//               <h2 className="text-xl font-semibold mb-2">Report by {report.user}</h2>
//               <div className="grid grid-cols-7 gap-4">
//                 <div className="col-span-1 font-medium">Monday:</div>
//                 <div className="col-span-6">{report.monday || 'No report'}</div>

//                 <div className="col-span-1 font-medium">Tuesday:</div>
//                 <div className="col-span-6">{report.tuesday || 'No report'}</div>

//                 <div className="col-span-1 font-medium">Wednesday:</div>
//                 <div className="col-span-6">{report.wednesday || 'No report'}</div>

//                 <div className="col-span-1 font-medium">Thursday:</div>
//                 <div className="col-span-6">{report.thursday || 'No report'}</div>

//                 <div className="col-span-1 font-medium">Friday:</div>
//                 <div className="col-span-6">{report.friday || 'No report'}</div>

//                 <div className="col-span-1 font-medium">Saturday:</div>
//                 <div className="col-span-6">{report.saturday || 'No report'}</div>

//                 <div className="col-span-1 font-medium">Sunday:</div>
//                 <div className="col-span-6">{report.sunday || 'No report'}</div>

//                 <div className="col-span-1 font-medium">Date:</div>
//                 <div className="col-span-6">{report.created_at}</div>
//               </div>
//             </div>
//           ))
//         ) : (
//           <div className="text-center py-4">No reports available</div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default WeeklyReportWithFetch;


import React, { useState, useEffect } from 'react';
// import secureLocalStorage from 'secure-localstorage';
import secureLocalStorage from 'react-secure-storage';


const WeeklyReportWithFetch = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const apiUrl = 'http://127.0.0.1:8000/api/weekly-report-agenda/';
        const token = secureLocalStorage.getItem('token'); // Retrieve the token from secure storage

        if (!token) {
          throw new Error('No token found');
        }

        console.log('Token:', token);  // Debug token

        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Token ${token}`, // Ensure header matches backend expectation
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(`Failed to fetch reports: ${response.status} ${errorMessage}`);
        }

        const data = await response.json();
        setReports(data.reports);
      } catch (error) {
        console.error('Error fetching reports:', error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  if (loading) {
    return <div className="text-center py-4">Loading...</div>;
  }

  if (error) {
    return <div className="text-center py-4 text-red-500">{error}</div>;
  }

  return (
    <div className="p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-2xl font-bold mb-4">Weekly Reports</h1>
      <div className="space-y-4">
        {reports.length > 0 ? (
          reports.map((report, index) => (
            <div
              key={index}
              className="border border-gray-200 p-4 rounded-lg bg-gray-50"
            >
              <h2 className="text-xl font-semibold mb-2">Report by {report.user}</h2>
              <div className="grid grid-cols-7 gap-4">
                <div className="col-span-1 font-medium">Monday:</div>
                <div className="col-span-6">{report.monday || 'No report'}</div>

                <div className="col-span-1 font-medium">Tuesday:</div>
                <div className="col-span-6">{report.tuesday || 'No report'}</div>

                <div className="col-span-1 font-medium">Wednesday:</div>
                <div className="col-span-6">{report.wednesday || 'No report'}</div>

                <div className="col-span-1 font-medium">Thursday:</div>
                <div className="col-span-6">{report.thursday || 'No report'}</div>

                <div className="col-span-1 font-medium">Friday:</div>
                <div className="col-span-6">{report.friday || 'No report'}</div>

                <div className="col-span-1 font-medium">Saturday:</div>
                <div className="col-span-6">{report.saturday || 'No report'}</div>

                <div className="col-span-1 font-medium">Sunday:</div>
                <div className="col-span-6">{report.sunday || 'No report'}</div>

                <div className="col-span-1 font-medium">Date:</div>
                <div className="col-span-6">{report.created_at}</div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-4">No reports available</div>
        )}
      </div>
    </div>
  );
};

export default WeeklyReportWithFetch;
