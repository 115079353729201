// import React, { useState } from 'react';
// import axios from 'axios';
// import secureLocalStorage from 'react-secure-storage';
// import { TextField, Button, CircularProgress } from '@mui/material';

// const WeeklyReportForm = () => {
//   const [report, setReport] = useState({
//     monday: '',
//     tuesday: '',
//     wednesday: '',
//     thursday: '',
//     friday: '',
//     saturday: '',
//     sunday: ''
//   });

//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setReport((prevReport) => ({
//       ...prevReport,
//       [name]: value
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setError('');

//     try {
//       const token = secureLocalStorage.getItem('token');
//       const response = await axios.post(
//         'https://api.eth-prosperity.com/api/weekly-report-agenda/',
//         report,
//         {
//           headers: {
//             Authorization: `Token ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       // Handle successful response
//       console.log('Report submitted successfully:', response.data);
//     } catch (error) {
//       setError('Failed to submit report');
//       console.error('Error submitting report:', error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="p-6">
//       <div className="max-w-md mx-auto p-6 bg-white rounded-md shadow-md">
//         <h2 className="text-2xl font-bold mb-4">Submit Weekly Report</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="space-y-4">
//             <TextField
//               label="Monday"
//               name="monday"
//               value={report.monday}
//               onChange={handleChange}
//               fullWidth
//             />
//             <TextField
//               label="Tuesday"
//               name="tuesday"
//               value={report.tuesday}
//               onChange={handleChange}
//               fullWidth
//             />
//             <TextField
//               label="Wednesday"
//               name="wednesday"
//               value={report.wednesday}
//               onChange={handleChange}
//               fullWidth
//             />
//             <TextField
//               label="Thursday"
//               name="thursday"
//               value={report.thursday}
//               onChange={handleChange}
//               fullWidth
//             />
//             <TextField
//               label="Friday"
//               name="friday"
//               value={report.friday}
//               onChange={handleChange}
//               fullWidth
//             />
//             <TextField
//               label="Saturday"
//               name="saturday"
//               value={report.saturday}
//               onChange={handleChange}
//               fullWidth
//             />
//             <TextField
//               label="Sunday"
//               name="sunday"
//               value={report.sunday}
//               onChange={handleChange}
//               fullWidth
//             />
//           </div>
//           <div className="mt-6">
//             <Button
//               type="submit"
//               variant="contained"
//               color="primary"
//               fullWidth
//               disabled={loading}
//             >
//               {loading ? <CircularProgress size={24} /> : 'Submit Report'}
//             </Button>
//           </div>
//           {error && <p className="mt-4 text-red-500">{error}</p>}
//         </form>
//       </div>
//     </div>
//   );
// };

// export default WeeklyReportForm;



import React, { useState } from 'react';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { TextField, Button, CircularProgress } from '@mui/material';

const WeeklyReportForm = () => {
  const [report, setReport] = useState({
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
    sunday: ''
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReport((prevReport) => ({
      ...prevReport,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');

    try {
      const token = secureLocalStorage.getItem('token');
      const response = await axios.post(
        'https://api.eth-prosperity.com/api/weekly-report-agenda/',
        report,
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      // Handle successful response
      setSuccessMessage('Report submitted successfully!');
    } catch (error) {
      setError('Failed to submit report');
      console.error('Error submitting report:', error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6">
      <div className="max-w-md mx-auto p-6 bg-white rounded-md shadow-md">
        <h2 className="text-2xl font-bold mb-4">Submit Weekly Report</h2>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <TextField
              label="Monday"
              name="monday"
              value={report.monday}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Tuesday"
              name="tuesday"
              value={report.tuesday}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Wednesday"
              name="wednesday"
              value={report.wednesday}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Thursday"
              name="thursday"
              value={report.thursday}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Friday"
              name="friday"
              value={report.friday}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Saturday"
              name="saturday"
              value={report.saturday}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Sunday"
              name="sunday"
              value={report.sunday}
              onChange={handleChange}
              fullWidth
            />
          </div>
          <div className="mt-6">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Submit Report'}
            </Button>
          </div>
          {successMessage && <p className="mt-4 text-green-500">{successMessage}</p>}
          {error && <p className="mt-4 text-red-500">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default WeeklyReportForm;
