// import React, { useState, useEffect, useMemo } from 'react';
// // import MaterialReactTable from 'material-react-table';
// import { MaterialReactTable } from 'material-react-table';

// import { CircularProgress } from '@mui/material';

// const PerformanceReportTable = () => {
//   const [reports, setReports] = useState([]);
//   const [loading, setLoading] = useState(true);

//   // Fetch performance reports from API on component mount
//   useEffect(() => {
//     const fetchReports = async () => {
//       try {
//         const apiUrl = 'https://api.yourdomain.com/api/performance-reports/';
//         const response = await fetch(apiUrl, {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Adjust this if using securelocalStorage
//           },
//         });
//         if (!response.ok) {
//           throw new Error('Failed to fetch reports');
//         }

//         const data = await response.json();
//         setReports(data);
//       } catch (error) {
//         console.error('Error fetching reports:', error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchReports();
//   }, []);

//   // Define the columns for the table
//   const columns = useMemo(
//     () => [
//       { accessorKey: 'id', header: 'ID', size: 50 },
//       { accessorKey: 'fb_total_posts', header: 'Facebook Total Posts', size: 100 },
//       { accessorKey: 'fb_reach_out', header: 'Facebook Reach Out', size: 100 },
//       { accessorKey: 'fb_engagement', header: 'Facebook Engagement', size: 100 },
//       { accessorKey: 'fb_new_followers', header: 'Facebook New Followers', size: 100 },
//       { accessorKey: 'tiktok_total_posts', header: 'TikTok Total Posts', size: 100 },
//       { accessorKey: 'tiktok_total_views', header: 'TikTok Total Views', size: 100 },
//       { accessorKey: 'tiktok_total_live', header: 'TikTok Total Live', size: 100 },
//       { accessorKey: 'tiktok_new_followers', header: 'TikTok New Followers', size: 100 },
//       { accessorKey: 'twitter_total_posts', header: 'Twitter Total Posts', size: 100 },
//       { accessorKey: 'twitter_reach_out', header: 'Twitter Reach Out', size: 100 },
//       { accessorKey: 'twitter_engagement', header: 'Twitter Engagement', size: 100 },
//       { accessorKey: 'twitter_new_followers', header: 'Twitter New Followers', size: 100 },
//       { accessorKey: 'created_at', header: 'Created At', size: 150, Cell: ({ cell }) => new Date(cell.getValue()).toLocaleString() },
//     ],
//     []
//   );

//   return (
//     <div className="p-6">
//       <div className="p-6 rounded-md shadow-lg">
//         <h2 className="text-2xl font-bold mb-4">Performance Reports</h2>
//         {loading ? (
//           <div className="flex justify-center items-center">
//             <CircularProgress />
//           </div>
//         ) : (
//           <MaterialReactTable
//             columns={columns}
//             data={reports}
//             enableColumnResizing
//             enablePagination
//             enableSorting
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// export default PerformanceReportTable;


import React, { useState, useEffect, useMemo } from 'react';
// import MaterialReactTable from 'material-react-table';
import { MaterialReactTable } from 'material-react-table';
import { CircularProgress } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';

const PerformanceReportTable = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch performance reports from API on component mount
  useEffect(() => {
    const fetchReports = async () => {
      try {
        const apiUrl = 'https://api.eth-prosperity.com/api/performance-report/';
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Token ${secureLocalStorage.getItem('token')}`, // Adjust this if using securelocalStorage
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch reports');
        }

        const data = await response.json();
        setReports(data);
      } catch (error) {
        console.error('Error fetching reports:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  // Define the columns for the table
  const columns = useMemo(
    () => [
      { accessorKey: 'id', header: 'ID', size: 60 },
      { accessorKey: 'created_at', header: 'Created At', size: 200, minSize: 150},
      { accessorKey: 'username', header: 'User', size: 150 }, // Assumes 'employee_name' is returned in the API
      { accessorKey: 'fb_total_posts', header: 'FB Total Posts', size: 150, minSize: 100, maxSize: 300 },
      { accessorKey: 'fb_reach_out', header: 'FB Reach Out', size: 150, minSize: 100, maxSize: 300 },
      { accessorKey: 'fb_engagement', header: 'FB Engagement', size: 150, minSize: 100, maxSize: 300 },
      { accessorKey: 'fb_new_followers', header: 'FB New Followers', size: 150, minSize: 100, maxSize: 300 },
      { accessorKey: 'tiktok_total_posts', header: 'TikTok Total Posts', size: 150, minSize: 100, maxSize: 300 },
      { accessorKey: 'tiktok_total_views', header: 'TikTok Total Views', size: 150, minSize: 100, maxSize: 300 },
      { accessorKey: 'tiktok_total_live', header: 'TikTok Total Live', size: 150, minSize: 100, maxSize: 300 },
      { accessorKey: 'tiktok_new_followers', header: 'TikTok New Followers', size: 150, minSize: 100, maxSize: 300 },
      { accessorKey: 'twitter_total_posts', header: 'Twitter Total Posts', size: 150, minSize: 100, maxSize: 300 },
      { accessorKey: 'twitter_reach_out', header: 'Twitter Reach Out', size: 150, minSize: 100, maxSize: 300 },
      { accessorKey: 'twitter_engagement', header: 'Twitter Engagement', size: 150, minSize: 100, maxSize: 300 },
      { accessorKey: 'twitter_new_followers', header: 'Twitter New Followers', size: 150, minSize: 100, maxSize: 300 },
      
    ],
    []
  );

  return (
    <div className="p-6">
      <div className="p-6 rounded-md shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Performance Reports</h2>
        {loading ? (
          <div className="flex justify-center items-center">
            <CircularProgress />
          </div>
        ) : (
          <MaterialReactTable
            columns={columns}
            data={reports}
            enableColumnResizing
            initialState={{
              columnVisibility: {
                // Set default visibility of columns here if needed
              },
              columnSizing: {
                fb_total_posts: 150, // Example: Setting default sizes
              },
            }}
            defaultColumn={{
              minSize: 100, // Minimum size for all columns
              maxSize: 300, // Maximum size for all columns
              size: 150,    // Default size for all columns
            }}
            enablePagination
            enableSorting
          />
        )}
      </div>
    </div>
  );
};

export default PerformanceReportTable;
