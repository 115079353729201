// // src/components/TikTokCard.js
// import React from 'react';
import tiktok from './tiktok.png';

// const TikTokCard = () => {
//   return (
//     <div className="bg-white rounded-lg shadow-lg p-6">
//       <img src={tiktok} alt="TikTok Logo" className="w-12 h-12 mx-auto mb-4" />
//       <h2 className="text-xl font-bold text-center mb-2">TikTok</h2>
//       {/* Add content here for TikTok metrics */}
//     </div>
//   );
// };

// export default TikTokCard;



// src/components/FacebookCard.js
import React from 'react';
// import facebookLogo from './facebook.png'; // Update the path to the actual location of the image in your project

const FacebookCard = () => {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <img src={tiktok} alt="Facebook Logo" className="w-12 h-12 mx-auto mb-4" />
      <h2 className="text-xl font-bold text-center mb-2">TikTok</h2>
      <h3 className="text-lg font-bold text-center mb-2">Report</h3>
      <ul className="list-disc pl-5 text-left">
        <li><a href="#account-number" className="text-blue-500 hover:underline">Total View</a></li>
        <li><a href="#number-of-likes" className="text-blue-500 hover:underline">Total Like</a></li>
        <li><a href="#top-agenda" className="text-blue-500 hover:underline">Total Follower</a></li>
      </ul>
      {/* <p className="mt-4">Other things to manage the account:</p>
      <ul className="list-disc pl-5 text-left">
        <li><a href="#monitor-activity" className="text-blue-500 hover:underline">Monitor page activity</a></li>
        <li><a href="#respond-comments" className="text-blue-500 hover:underline">Respond to comments and messages</a></li>
        <li><a href="#post-updates" className="text-blue-500 hover:underline">Post regular updates</a></li>
        <li><a href="#analyze-performance" className="text-blue-500 hover:underline">Analyze page performance</a></li>
      </ul> */}
    </div>
  );
};

export default FacebookCard;