import React, { useState } from 'react';

const TwitterReportTable = () => {
  const [totalActiveAccounts, setTotalActiveAccounts] = useState(17);

  const handleTotalActiveAccountsChange = (value) => {
    setTotalActiveAccounts(value);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Twitter Report</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b">Total Active Accounts</td>
              <td className="py-2 px-4 border-b">
                <input
                  type="number"
                  value={totalActiveAccounts}
                  onChange={(e) => handleTotalActiveAccountsChange(e.target.value)}
                  className="border px-2 py-1"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TwitterReportTable;
