import React, { useState } from 'react';

const FacebookReportTable = () => {
  const [totalActiveAccounts, setTotalActiveAccounts] = useState(21);
  const [totalActivePages, setTotalActivePages] = useState(7);
  const [totalPageFollowers, setTotalPageFollowers] = useState(222);
  const [totalPageEngagement, setTotalPageEngagement] = useState(12245);

  const handleTotalActiveAccountsChange = (value) => {
    setTotalActiveAccounts(value);
  };

  const handleTotalActivePagesChange = (value) => {
    setTotalActivePages(value);
  };

  const handleTotalPageFollowersChange = (value) => {
    setTotalPageFollowers(value);
  };

  const handleTotalPageEngagementChange = (value) => {
    setTotalPageEngagement(value);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Facebook Report</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b">Total Active Accounts</td>
              <td className="py-2 px-4 border-b">
                <input
                  type="number"
                  value={totalActiveAccounts}
                  onChange={(e) => handleTotalActiveAccountsChange(e.target.value)}
                  className="border px-2 py-1"
                />
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Total Active Pages</td>
              <td className="py-2 px-4 border-b">
                <input
                  type="number"
                  value={totalActivePages}
                  onChange={(e) => handleTotalActivePagesChange(e.target.value)}
                  className="border px-2 py-1"
                />
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Total Page Followers</td>
              <td className="py-2 px-4 border-b">
                <input
                  type="number"
                  value={totalPageFollowers}
                  onChange={(e) => handleTotalPageFollowersChange(e.target.value)}
                  className="border px-2 py-1"
                />
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Total Page Engagement (weekly)</td>
              <td className="py-2 px-4 border-b">
                <input
                  type="number"
                  value={totalPageEngagement}
                  onChange={(e) => handleTotalPageEngagementChange(e.target.value)}
                  className="border px-2 py-1"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FacebookReportTable;
