// // export default PerformanceReportForm;
// import React, { useState } from 'react';
// import secureLocalStorage from 'react-secure-storage';
// import tiktok from './tiktok.png';
// import twitter from './twitter.png';
// import facebookLogo from './facebook.png';

// const PerformanceReportForm = () => {
//   const [formData, setFormData] = useState({
//     fb_total_posts: '',
//     fb_reach_out: '',
//     fb_engagement: '',
//     fb_new_followers: '',
//     tiktok_total_posts: '',
//     tiktok_total_views: '',
//     tiktok_total_live: '',
//     tiktok_new_followers: '',
//     twitter_total_posts: '',
//     twitter_reach_out: '',
//     twitter_engagement: '',
//     twitter_new_followers: '',
//   });

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Retrieve the token from secure storage
//     const token = secureLocalStorage.getItem('token');

//     if (!token) {
//       console.error('No token found in local storage');
//       return;
//     }

//     try {
//       const response = await fetch('https://api.eth-prosperity.com/api/performance-report/', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Token ${token}`,
//         },
//         body: JSON.stringify(formData),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to submit report');
//       }

//       const result = await response.json();
//       console.log('Report submitted successfully:', result);
//       // Optionally reset the form or provide user feedback
//       setFormData({
//         fb_total_posts: '',
//         fb_reach_out: '',
//         fb_engagement: '',
//         fb_new_followers: '',
//         tiktok_total_posts: '',
//         tiktok_total_views: '',
//         tiktok_total_live: '',
//         tiktok_new_followers: '',
//         twitter_total_posts: '',
//         twitter_reach_out: '',
//         twitter_engagement: '',
//         twitter_new_followers: '',
//       });
//     } catch (error) {
//       console.error('Error submitting report:', error.message);
//     }
//   };

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   return (
//     <div className="max-w-2xl mx-auto mt-10 p-6 bg-white shadow-md rounded-lg">
//       <h2 className="text-2xl font-bold text-center mb-6">ሳምንታዊ ሪፖርት</h2>
//       <form onSubmit={handleSubmit} className="space-y-4">
//         {/* Form Fields for Facebook, TikTok, and Twitter */}
//         {/* Facebook Fields */}
//         <div>
//         <div className='flex flex-row justify-center items-center bg-slate-500 p-1 rounded text-white'>
//   <h3 className="text-lg font-semibold ml-2">Facebook</h3>
//   <img src={facebookLogo} alt="Facebook Logo" className="w-12 h-12 mx-2 p-1 bg-white" />
// </div>
    
//           <div className="grid grid-cols-2 gap-4">
//             <div>
//               <label className="block font-medium mb-1">Total Posts</label>
//               <input
//                 type="number"
//                 name="fb_total_posts"
//                 value={formData.fb_total_posts}
//                 onChange={handleChange}
//                 className="w-full p-2 border rounded-md"
//               />
//             </div>
//             <div>
//               <label className="block font-medium mb-1">Reach Out</label>
//               <input
//                 type="number"
//                 name="fb_reach_out"
//                 value={formData.fb_reach_out}
//                 onChange={handleChange}
//                 className="w-full p-2 border rounded-md"
//               />
//             </div>
//             <div>
//               <label className="block font-medium mb-1">Engagement</label>
//               <input
//                 type="number"
//                 name="fb_engagement"
//                 value={formData.fb_engagement}
//                 onChange={handleChange}
//                 className="w-full p-2 border rounded-md"
//               />
//             </div>
//             <div>
//               <label className="block font-medium mb-1">New Followers</label>
//               <input
//                 type="number"
//                 name="fb_new_followers"
//                 value={formData.fb_new_followers}
//                 onChange={handleChange}
//                 className="w-full p-2 border rounded-md"
//               />
//             </div>
//           </div>
//         </div>

//         {/* TikTok Fields */}
//         <div>
//         <div className='flex flex-row justify-center items-center bg-slate-500 p-1 rounded'>
//   <h3 className="text-lg font-semibold ml-2 text-white">Tiktok</h3>
//   <img src={tiktok} alt="Facebook Logo" className="w-12 h-12 mx-2 p-1 bg-white" />
// </div>
//           <div className="grid grid-cols-2 gap-4">
//             <div>
//               <label className="block font-medium mb-1">Total Posts</label>
//               <input
//                 type="number"
//                 name="tiktok_total_posts"
//                 value={formData.tiktok_total_posts}
//                 onChange={handleChange}
//                 className="w-full p-2 border rounded-md"
//               />
//             </div>
//             <div>
//               <label className="block font-medium mb-1">Total Views</label>
//               <input
//                 type="number"
//                 name="tiktok_total_views"
//                 value={formData.tiktok_total_views}
//                 onChange={handleChange}
//                 className="w-full p-2 border rounded-md"
//               />
//             </div>
//             <div>
//               <label className="block font-medium mb-1">Total Live</label>
//               <input
//                 type="number"
//                 name="tiktok_total_live"
//                 value={formData.tiktok_total_live}
//                 onChange={handleChange}
//                 className="w-full p-2 border rounded-md"
//               />
//             </div>
//             <div>
//               <label className="block font-medium mb-1">New Followers</label>
//               <input
//                 type="number"
//                 name="tiktok_new_followers"
//                 value={formData.tiktok_new_followers}
//                 onChange={handleChange}
//                 className="w-full p-2 border rounded-md"
//               />
//             </div>
//           </div>
//         </div>

//         {/* Twitter Fields */}
//         <div>
//         <div className='flex flex-row justify-center items-center bg-slate-500 p-1 rounded'>
//   <h3 className="text-lg font-semibold ml-2 text-white">Twitter</h3>
//   <img src={twitter} alt="Facebook Logo" className="w-12 h-12 mx-2 p-1 bg-white" />
// </div>
//           <div className="grid grid-cols-2 gap-4">
//             <div>
//               <label className="block font-medium mb-1">Total Posts</label>
//               <input
//                 type="number"
//                 name="twitter_total_posts"
//                 value={formData.twitter_total_posts}
//                 onChange={handleChange}
//                 className="w-full p-2 border rounded-md"
//               />
//             </div>
//             <div>
//               <label className="block font-medium mb-1">Reach Out</label>
//               <input
//                 type="number"
//                 name="twitter_reach_out"
//                 value={formData.twitter_reach_out}
//                 onChange={handleChange}
//                 className="w-full p-2 border rounded-md"
//               />
//             </div>
//             <div>
//               <label className="block font-medium mb-1">Engagement</label>
//               <input
//                 type="number"
//                 name="twitter_engagement"
//                 value={formData.twitter_engagement}
//                 onChange={handleChange}
//                 className="w-full p-2 border rounded-md"
//               />
//             </div>
//             <div>
//               <label className="block font-medium mb-1">New Followers</label>
//               <input
//                 type="number"
//                 name="twitter_new_followers"
//                 value={formData.twitter_new_followers}
//                 onChange={handleChange}
//                 className="w-full p-2 border rounded-md"
//               />
//             </div>
//           </div>
//         </div>

//         <div className="text-center mt-6">
//           <button
//             type="submit"
//             className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200"
//           >
//             Submit Report
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default PerformanceReportForm;



import React, { useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import tiktok from './tiktok.png';
import twitter from './twitter.png';
import facebookLogo from './facebook.png';

const PerformanceReportForm = () => {
  const [formData, setFormData] = useState({
    fb_total_posts: '',
    fb_reach_out: '',
    fb_engagement: '',
    fb_new_followers: '',
    tiktok_total_posts: '',
    tiktok_total_views: '',
    tiktok_total_live: '',
    tiktok_new_followers: '',
    twitter_total_posts: '',
    twitter_reach_out: '',
    twitter_engagement: '',
    twitter_new_followers: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Retrieve the token from secure storage
    const token = secureLocalStorage.getItem('token');

    if (!token) {
      console.error('No token found in local storage');
      return;
    }

    try {
      const response = await fetch('https://api.eth-prosperity.com/api/performance-report/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('ያላስገቡትን ፊልድ ያስገቡ ሁሉም መሞላቱን ያረጋግጡ');
      }

      const result = await response.json();
      console.log('Report submitted successfully:', result);

      // Set success message and reset the form
      setSuccessMessage('በሚገባ ልከዋል እናመሰግናለን!');
      setFormData({
        fb_total_posts: '',
        fb_reach_out: '',
        fb_engagement: '',
        fb_new_followers: '',
        tiktok_total_posts: '',
        tiktok_total_views: '',
        tiktok_total_live: '',
        tiktok_new_followers: '',
        twitter_total_posts: '',
        twitter_reach_out: '',
        twitter_engagement: '',
        twitter_new_followers: '',
      });
      setError(''); // Clear any previous errors
    } catch (error) {
      setError('ያላስገቡትን ፊልድ ያስገቡ ሁሉም መሞላቱን ያረጋግጡ');
      console.error('Error submitting report:', error.message);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="max-w-2xl mx-auto mt-10 p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold text-center mb-6">ሳምንታዊ ሪፖርት</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Facebook Fields */}
        <div>
          <div className='flex flex-row justify-center items-center bg-slate-500 p-1 rounded text-white'>
            <h3 className="text-lg font-semibold ml-2">Facebook</h3>
            <img src={facebookLogo} alt="Facebook Logo" className="w-12 h-12 mx-2 p-1 bg-white" />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block font-medium mb-1">Total Posts</label>
              <input
                type="number"
                name="fb_total_posts"
                value={formData.fb_total_posts}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block font-medium mb-1">Reach Out</label>
              <input
                type="number"
                name="fb_reach_out"
                value={formData.fb_reach_out}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block font-medium mb-1">Engagement</label>
              <input
                type="number"
                name="fb_engagement"
                value={formData.fb_engagement}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block font-medium mb-1">New Followers</label>
              <input
                type="number"
                name="fb_new_followers"
                value={formData.fb_new_followers}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
              />
            </div>
          </div>
        </div>

        {/* TikTok Fields */}
        <div>
          <div className='flex flex-row justify-center items-center bg-slate-500 p-1 rounded'>
            <h3 className="text-lg font-semibold ml-2 text-white">Tiktok</h3>
            <img src={tiktok} alt="Tiktok Logo" className="w-12 h-12 mx-2 p-1 bg-white" />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block font-medium mb-1">Total Posts</label>
              <input
                type="number"
                name="tiktok_total_posts"
                value={formData.tiktok_total_posts}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block font-medium mb-1">Total Views</label>
              <input
                type="number"
                name="tiktok_total_views"
                value={formData.tiktok_total_views}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block font-medium mb-1">Total Live</label>
              <input
                type="number"
                name="tiktok_total_live"
                value={formData.tiktok_total_live}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block font-medium mb-1">New Followers</label>
              <input
                type="number"
                name="tiktok_new_followers"
                value={formData.tiktok_new_followers}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
              />
            </div>
          </div>
        </div>

        {/* Twitter Fields */}
        <div>
          <div className='flex flex-row justify-center items-center bg-slate-500 p-1 rounded'>
            <h3 className="text-lg font-semibold ml-2 text-white">Twitter</h3>
            <img src={twitter} alt="Twitter Logo" className="w-12 h-12 mx-2 p-1 bg-white" />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block font-medium mb-1">Total Posts</label>
              <input
                type="number"
                name="twitter_total_posts"
                value={formData.twitter_total_posts}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block font-medium mb-1">Reach Out</label>
              <input
                type="number"
                name="twitter_reach_out"
                value={formData.twitter_reach_out}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block font-medium mb-1">Engagement</label>
              <input
                type="number"
                name="twitter_engagement"
                value={formData.twitter_engagement}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block font-medium mb-1">New Followers</label>
              <input
                type="number"
                name="twitter_new_followers"
                value={formData.twitter_new_followers}
                onChange={handleChange}
                className="w-full p-2 border rounded-md"
              />
            </div>
          </div>
        </div>

        <div className="text-center mt-6">
          <button
            type="submit"
            className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200"
          >
            Submit Report
          </button>
        </div>

        {/* Success and Error Messages */}
        {successMessage && <p className="mt-4 text-green-500 text-center">{successMessage}</p>}
        {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
      </form>
    </div>
  );
};

export default PerformanceReportForm;
