import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PerformanceTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from the Django REST backend
    axios.get('https://api.eth-prosperity.com/api/performance/')
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  }, []);

  const handleInputChange = (e, index, platform, field) => {
    const newData = [...data];
    newData[index][`${platform}_${field}`] = e.target.value;
    setData(newData);
  };

  const handleSubmit = () => {
    // Submit updated data to the backend
    axios.post('https://api.eth-prosperity.com/api/performance/', data)
      .then(response => {
        alert('Data saved successfully!');
      })
      .catch(error => {
        console.error('There was an error saving the data!', error);
      });
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" colSpan="4">Facebook</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" colSpan="4">Tiktok</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" colSpan="4">Twitter</th>
          </tr>
          <tr>
            <th className="px-6 py-3 bg-gray-50"></th>
            <th className="px-6 py-3 bg-gray-50">Total post</th>
            <th className="px-6 py-3 bg-gray-50">Reach out</th>
            <th className="px-6 py-3 bg-gray-50">Engagement</th>
            <th className="px-6 py-3 bg-gray-50">New Follower</th>
            <th className="px-6 py-3 bg-gray-50">Total post</th>
            <th className="px-6 py-3 bg-gray-50">Total view</th>
            <th className="px-6 py-3 bg-gray-50">Total live</th>
            <th className="px-6 py-3 bg-gray-50">New follower</th>
            <th className="px-6 py-3 bg-gray-50">Total post</th>
            <th className="px-6 py-3 bg-gray-50">Reach out</th>
            <th className="px-6 py-3 bg-gray-50">Engagement</th>
            <th className="px-6 py-3 bg-gray-50">New follower</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((person, idx) => (
            <tr key={idx}>
              <td className="px-6 py-4 whitespace-nowrap">{person.name}</td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="number"
                  placeholder="Total post"
                  value={person.facebook_total_post || ''}
                  onChange={(e) => handleInputChange(e, idx, 'facebook', 'total_post')}
                  className="w-full px-2 py-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="number"
                  placeholder="Reach out"
                  value={person.facebook_reach_out || ''}
                  onChange={(e) => handleInputChange(e, idx, 'facebook', 'reach_out')}
                  className="w-full px-2 py-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="number"
                  placeholder="Engagement"
                  value={person.facebook_engagement || ''}
                  onChange={(e) => handleInputChange(e, idx, 'facebook', 'engagement')}
                  className="w-full px-2 py-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="number"
                  placeholder="New Follower"
                  value={person.facebook_new_follower || ''}
                  onChange={(e) => handleInputChange(e, idx, 'facebook', 'new_follower')}
                  className="w-full px-2 py-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="number"
                  placeholder="Total post"
                  value={person.tiktok_total_post || ''}
                  onChange={(e) => handleInputChange(e, idx, 'tiktok', 'total_post')}
                  className="w-full px-2 py-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="number"
                  placeholder="Total view"
                  value={person.tiktok_total_view || ''}
                  onChange={(e) => handleInputChange(e, idx, 'tiktok', 'total_view')}
                  className="w-full px-2 py-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="number"
                  placeholder="Total live"
                  value={person.tiktok_total_live || ''}
                  onChange={(e) => handleInputChange(e, idx, 'tiktok', 'total_live')}
                  className="w-full px-2 py-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="number"
                  placeholder="New follower"
                  value={person.tiktok_new_follower || ''}
                  onChange={(e) => handleInputChange(e, idx, 'tiktok', 'new_follower')}
                  className="w-full px-2 py-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="number"
                  placeholder="Total post"
                  value={person.twitter_total_post || ''}
                  onChange={(e) => handleInputChange(e, idx, 'twitter', 'total_post')}
                  className="w-full px-2 py-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="number"
                  placeholder="Reach out"
                  value={person.twitter_reach_out || ''}
                  onChange={(e) => handleInputChange(e, idx, 'twitter', 'reach_out')}
                  className="w-full px-2 py-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="number"
                  placeholder="Engagement"
                  value={person.twitter_engagement || ''}
                  onChange={(e) => handleInputChange(e, idx, 'twitter', 'engagement')}
                  className="w-full px-2 py-1 border rounded"
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <input
                  type="number"
                  placeholder="New follower"
                  value={person.twitter_new_follower || ''}
                  onChange={(e) => handleInputChange(e, idx, 'twitter', 'new_follower')}
                  className="w-full px-2 py-1 border rounded"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        onClick={handleSubmit}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
      >
        Save
      </button>
    </div>
  );
};

export default PerformanceTable;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';

// const PerformanceTable = () => {
//   const [data, setData] = useState([]);
//   const loggedInUser = JSON.parse(localStorage.getItem('user'));
//   const isManager = loggedInUser.role === '1';

//   useEffect(() => {
//     // Fetch data from the Django REST backend
//     axios.get('https://api.eth-prosperity.com/api/performance/')
//       .then(response => {
//         setData(response.data);
//       })
//       .catch(error => {
//         console.error('There was an error fetching the data!', error);
//       });
//   }, []);

//   const handleInputChange = (e, index, platform, field) => {
//     const newData = [...data];
//     newData[index][`${platform}_${field}`] = e.target.value;
//     setData(newData);
//   };

//   const handleSubmit = () => {
//     // Submit updated data to the backend
//     axios.post('https://api.eth-prosperity.com/api/performance/', data)
//       .then(response => {
//         alert('Data saved successfully!');
//       })
//       .catch(error => {
//         console.error('There was an error saving the data!', error);
//       });
//   };

//   const handleSendToManager = () => {
//     // Implement logic to send data to manager
//     alert('Data sent to manager!');
//   };

//   const handleDownloadPDF = () => {
//     const doc = new jsPDF();
//     doc.text('Performance Data', 20, 10);
//     doc.autoTable({ html: '#performance-table' });
//     doc.save('performance_data.pdf');
//   };

//   return (
//     <div className="overflow-x-auto">
//       <table id="performance-table" className="min-w-full divide-y divide-gray-200">
//         <thead className="bg-gray-50">
//           <tr>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" colSpan="4">Facebook</th>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" colSpan="4">Tiktok</th>
//             <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" colSpan="4">Twitter</th>
//           </tr>
//           <tr>
//             <th className="px-6 py-3 bg-gray-50"></th>
//             <th className="px-6 py-3 bg-gray-50">Total post</th>
//             <th className="px-6 py-3 bg-gray-50">Reach out</th>
//             <th className="px-6 py-3 bg-gray-50">Engagement</th>
//             <th className="px-6 py-3 bg-gray-50">New Follower</th>
//             <th className="px-6 py-3 bg-gray-50">Total post</th>
//             <th className="px-6 py-3 bg-gray-50">Total view</th>
//             <th className="px-6 py-3 bg-gray-50">Total live</th>
//             <th className="px-6 py-3 bg-gray-50">New follower</th>
//             <th className="px-6 py-3 bg-gray-50">Total post</th>
//             <th className="px-6 py-3 bg-gray-50">Reach out</th>
//             <th className="px-6 py-3 bg-gray-50">Engagement</th>
//             <th className="px-6 py-3 bg-gray-50">New follower</th>
//           </tr>
//         </thead>
//         <tbody className="bg-white divide-y divide-gray-200">
//           {data.filter(person => isManager || person.name === loggedInUser.name).map((person, idx) => (
//             <tr key={idx}>
//               <td className="px-6 py-4 whitespace-nowrap">{person.name}</td>
//               <td className="px-6 py-4 whitespace-nowrap">
//                 <input
//                   type="number"
//                   placeholder="Total post"
//                   value={person.facebook_total_post || ''}
//                   onChange={(e) => handleInputChange(e, idx, 'facebook', 'total_post')}
//                   className="w-full px-2 py-1 border rounded"
//                 />
//               </td>
//               <td className="px-6 py-4 whitespace-nowrap">
//                 <input
//                   type="number"
//                   placeholder="Reach out"
//                   value={person.facebook_reach_out || ''}
//                   onChange={(e) => handleInputChange(e, idx, 'facebook', 'reach_out')}
//                   className="w-full px-2 py-1 border rounded"
//                 />
//               </td>
//               <td className="px-6 py-4 whitespace-nowrap">
//                 <input
//                   type="number"
//                   placeholder="Engagement"
//                   value={person.facebook_engagement || ''}
//                   onChange={(e) => handleInputChange(e, idx, 'facebook', 'engagement')}
//                   className="w-full px-2 py-1 border rounded"
//                 />
//               </td>
//               <td className="px-6 py-4 whitespace-nowrap">
//                 <input
//                   type="number"
//                   placeholder="New Follower"
//                   value={person.facebook_new_follower || ''}
//                   onChange={(e) => handleInputChange(e, idx, 'facebook', 'new_follower')}
//                   className="w-full px-2 py-1 border rounded"
//                 />
//               </td>
//               <td className="px-6 py-4 whitespace-nowrap">
//                 <input
//                   type="number"
//                   placeholder="Total post"
//                   value={person.tiktok_total_post || ''}
//                   onChange={(e) => handleInputChange(e, idx, 'tiktok', 'total_post')}
//                   className="w-full px-2 py-1 border rounded"
//                 />
//               </td>
//               <td className="px-6 py-4 whitespace-nowrap">
//                 <input
//                   type="number"
//                   placeholder="Total view"
//                   value={person.tiktok_total_view || ''}
//                   onChange={(e) => handleInputChange(e, idx, 'tiktok', 'total_view')}
//                   className="w-full px-2 py-1 border rounded"
//                 />
//               </td>
//               <td className="px-6 py-4 whitespace-nowrap">
//                 <input
//                   type="number"
//                   placeholder="Total live"
//                   value={person.tiktok_total_live || ''}
//                   onChange={(e) => handleInputChange(e, idx, 'tiktok', 'total_live')}
//                   className="w-full px-2 py-1 border rounded"
//                 />
//               </td>
//               <td className="px-6 py-4 whitespace-nowrap">
//                 <input
//                   type="number"
//                   placeholder="New follower"
//                   value={person.tiktok_new_follower || ''}
//                   onChange={(e) => handleInputChange(e, idx, 'tiktok', 'new_follower')}
//                   className="w-full px-2 py-1 border rounded"
//                 />
//               </td>
//               <td className="px-6 py-4 whitespace-nowrap">
//                 <input
//                   type="number"
//                   placeholder="Total post"
//                   value={person.twitter_total_post || ''}
//                   onChange={(e) => handleInputChange(e, idx, 'twitter', 'total_post')}
//                   className="w-full px-2 py-1 border rounded"
//                 />
//               </td>
//               <td className="px-6 py-4 whitespace-nowrap">
//                 <input
//                   type="number"
//                   placeholder="Reach out"
//                   value={person.twitter_reach_out || ''}
//                   onChange={(e) => handleInputChange(e, idx, 'twitter', 'reach_out')}
//                   className="w-full px-2 py-1 border rounded"
//                 />
//               </td>
//               <td className="px-6 py-4 whitespace-nowrap">
//                 <input
//                   type="number"
//                   placeholder="Engagement"
//                   value={person.twitter_engagement || ''}
//                   onChange={(e) => handleInputChange(e, idx, 'twitter', 'engagement')}
//                   className="w-full px-2 py-1 border rounded"
//                 />
//               </td>
//               <td className="px-6 py-4 whitespace-nowrap">
//                 <input
//                   type="number"
//                   placeholder="New follower"
//                   value={person.twitter_new_follower || ''}
//                   onChange={(e) => handleInputChange(e, idx, 'twitter', 'new_follower')}
//                   className="w-full px-2 py-1 border rounded"
//                 />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <div className="mt-4 space-x-4">
//         <button
//           onClick={handleSubmit}
//           className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
//         >
//           Save
//         </button>
//         <button
//           onClick={handleSendToManager}
//           className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
//         >
//           Send to Manager
//         </button>
//         <button
//           onClick={handleDownloadPDF}
//           className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
//         >
//           Download PDF
//         </button>
//       </div>
//     </div>
//   );
// };

// export default PerformanceTable;

