import React, { useState } from 'react';

const TikTokReportTable = () => {
  const [totalAccounts, setTotalAccounts] = useState(7);
  const [totalFollowers, setTotalFollowers] = useState(240);
  const [totalLikes, setTotalLikes] = useState(20);
  const [totalPosts, setTotalPosts] = useState(11);
  const [totalReachOut, setTotalReachOut] = useState(2847);

  const handleTotalAccountsChange = (value) => {
    setTotalAccounts(value);
  };

  const handleTotalFollowersChange = (value) => {
    setTotalFollowers(value);
  };

  const handleTotalLikesChange = (value) => {
    setTotalLikes(value);
  };

  const handleTotalPostsChange = (value) => {
    setTotalPosts(value);
  };

  const handleTotalReachOutChange = (value) => {
    setTotalReachOut(value);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">TikTok Report</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b">Total Accounts</td>
              <td className="py-2 px-4 border-b">
                <input
                  type="number"
                  value={totalAccounts}
                  onChange={(e) => handleTotalAccountsChange(e.target.value)}
                  className="border px-2 py-1"
                />
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Total Followers</td>
              <td className="py-2 px-4 border-b">
                <input
                  type="number"
                  value={totalFollowers}
                  onChange={(e) => handleTotalFollowersChange(e.target.value)}
                  className="border px-2 py-1"
                />
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Total Likes</td>
              <td className="py-2 px-4 border-b">
                <input
                  type="number"
                  value={totalLikes}
                  onChange={(e) => handleTotalLikesChange(e.target.value)}
                  className="border px-2 py-1"
                />
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Total Posts</td>
              <td className="py-2 px-4 border-b">
                <input
                  type="number"
                  value={totalPosts}
                  onChange={(e) => handleTotalPostsChange(e.target.value)}
                  className="border px-2 py-1"
                />
              </td>
            </tr>
            <tr>
              <td className="py-2 px-4 border-b">Total Reach Out</td>
              <td className="py-2 px-4 border-b">
                <input
                  type="number"
                  value={totalReachOut}
                  onChange={(e) => handleTotalReachOutChange(e.target.value)}
                  className="border px-2 py-1"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TikTokReportTable;
