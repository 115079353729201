// import React, { useState, useEffect, useMemo } from 'react';
// import { MaterialReactTable } from 'material-react-table';
// import { CircularProgress } from '@mui/material';
// import secureLocalStorage from 'react-secure-storage';

// const WeeklyReportTable = () => {
//   const [reports, setReports] = useState([]);
//   const [loading, setLoading] = useState(true);

//   // Fetch weekly reports from API on component mount
//   useEffect(() => {
//     const fetchReports = async () => {
//       try {
//         const apiUrl = 'https://api.eth-prosperity.com/api/performance-report/';
//         const response = await fetch(apiUrl, {
//           headers: {
//             Authorization: `Token ${secureLocalStorage.getItem('token')}`,
//           },
//         });
//         if (!response.ok) {
//           throw new Error('Failed to fetch reports');
//         }

//         const data = await response.json();
//         setReports(data.reports);
//       } catch (error) {
//         console.error('Error fetching reports:', error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchReports();
//   }, []);

//   // Define the columns for the table
//   const columns = useMemo(
//     () => [
//       { accessorKey: 'user', header: 'User', size: 150 },
//       { accessorKey: 'monday', header: 'Monday', size: 100 },
//       { accessorKey: 'tuesday', header: 'Tuesday', size: 100 },
//       { accessorKey: 'wednesday', header: 'Wednesday', size: 100 },
//       { accessorKey: 'thursday', header: 'Thursday', size: 100 },
//       { accessorKey: 'friday', header: 'Friday', size: 100 },
//       { accessorKey: 'saturday', header: 'Saturday', size: 100 },
//       { accessorKey: 'sunday', header: 'Sunday', size: 100 },
//       { accessorKey: 'created_at', header: 'Created At', size: 200 },
//     ],
//     []
//   );

//   return (
//     <div className="p-6">
//       <div className="p-6 rounded-md shadow-lg">
//         <h2 className="text-2xl font-bold mb-4">Weekly Reports</h2>
//         {loading ? (
//           <div className="flex justify-center items-center">
//             <CircularProgress />
//           </div>
//         ) : (
//           <MaterialReactTable
//             columns={columns}
//             data={reports}
//             enableColumnResizing
//             initialState={{
//               columnVisibility: {
//                 // Set default visibility of columns here if needed
//               },
//               columnSizing: {
//                 monday: 100, // Example: Setting default sizes
//               },
//             }}
//             defaultColumn={{
//               minSize: 100,
//               maxSize: 300,
//               size: 150,
//             }}
//             enablePagination
//             enableSorting
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// export default WeeklyReportTable;


import React, { useState, useEffect, useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { CircularProgress } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';

const WeeklyReportTable = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch weekly reports from API on component mount
  useEffect(() => {
    const fetchReports = async () => {
      try {
        const apiUrl = 'https://api.eth-prosperity.com/api/weekly-report-agenda/';
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Token ${secureLocalStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch reports');
        }

        const data = await response.json();
        // Ensure 'reports' is an array
        if (Array.isArray(data.reports)) {
          setReports(data.reports);
        } else {
          console.error('Unexpected data format:', data);
        }
      } catch (error) {
        console.error('Error fetching reports:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  // Define the columns for the table
  const columns = useMemo(
    () => [
      { accessorKey: 'user', header: 'User', size: 150 },
      { accessorKey: 'monday', header: 'Monday', size: 100 },
      { accessorKey: 'tuesday', header: 'Tuesday', size: 100 },
      { accessorKey: 'wednesday', header: 'Wednesday', size: 100 },
      { accessorKey: 'thursday', header: 'Thursday', size: 100 },
      { accessorKey: 'friday', header: 'Friday', size: 100 },
      { accessorKey: 'saturday', header: 'Saturday', size: 100 },
      { accessorKey: 'sunday', header: 'Sunday', size: 100 },
      { accessorKey: 'created_at', header: 'Created At', size: 200 },
    ],
    []
  );

  return (
    <div className="p-6">
      <div className="p-6 rounded-md shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Weekly Agenda</h2>
        {loading ? (
          <div className="flex justify-center items-center">
            <CircularProgress />
          </div>
        ) : (
          <MaterialReactTable
            columns={columns}
            data={reports} // Pass the reports array directly
            enableColumnResizing
            initialState={{
              columnVisibility: {
                // Set default visibility of columns here if needed
              },
              columnSizing: {
                monday: 100, // Example: Setting default sizes
              },
            }}
            defaultColumn={{
              minSize: 100,
              maxSize: 300,
              size: 150,
            }}
            enablePagination
            enableSorting
          />
        )}
      </div>
    </div>
  );
};

export default WeeklyReportTable;
