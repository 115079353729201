// src/Dashboard.js
import React, { useState } from 'react';
import FacebookCard from './FacebookCard';
import TwitterCard from './TwitterCard';
import TikTokCard from './TikTokCard';
import BasicStacking from './BasicStacking';
import PieAnimation from './PieAnimation';

function Dashboard() {
  const [showPieAnimation, setShowPieAnimation] = useState(false); // Change this to true if you want to show PieAnimation by default

  return (
    <div className="container mx-auto">
      <h1 className="text-3xl font-bold text-center mb-8">Social Media Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <FacebookCard />
        <TwitterCard />
        <TikTokCard />
      </div>
      <div>
        {showPieAnimation ? <PieAnimation/> : <BasicStacking />}
        <button onClick={() => setShowPieAnimation(!showPieAnimation)}>
          Toggle Chart
        </button>
      </div>
    </div>
  );
}

export default Dashboard;

