// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const EmployeeData = () => {
//     const [employeeData, setEmployeeData] = useState([]);

//     useEffect(() => {
//         const requestData = {
//             jsonrpc: "2.0",
//             id: 1,
//             method: "call",
//             params: {
//                 service: "object",
//                 method: "execute_kw",
//                 args: [
//                     "new",
//                     2,
//                     "new",
//                     "hr.employee",
//                     "search_read",
//                     [[], ["name", "work_email"]]
//                 ]
//             }
//         };

//         axios.post('http://localhost:8069/jsonrpc', requestData)
//             .then(response => {
//                 console.log('Data fetched successfully:', response.data.result);
//                 setEmployeeData(response.data.result);
//             })
//             .catch(error => {
//                 console.error('Failed to fetch data:', error);
//             });
//     }, []);

//     return (
//         <div>
//             <h2>Employee Data:</h2>
//             <ul>
//                 {employeeData.map(employee => (
//                     <li key={employee.id}>
//                         <strong>Name:</strong> {employee.name}, <strong>Email:</strong> {employee.work_email}
//                     </li>
//                 ))}
//             </ul>
//         </div>
//     );
// };

// export default EmployeeData;


import React, { useEffect, useState } from 'react';
import axios from 'axios';

const EmployeeData = () => {
    const [employeeData, setEmployeeData] = useState([]);

    useEffect(() => {
        const requestData = {
            jsonrpc: "2.0",
            id: 1,
            method: "call",
            params: {
                service: "object",
                method: "execute_kw",
                args: [
                    "new",
                    2,
                    "new",
                    "crm.lead",
                    "search_read",
                    [[], ["name","contact_name"]]
                ]
            }
        };

        axios.post('http://localhost:8069/jsonrpc', requestData)
            .then(response => {
                console.log('Data fetched successfully:', response.data.result);
                setEmployeeData(response.data.result);
            })
            .catch(error => {
                console.error('Failed to fetch data:', error);
            });
    }, []);

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Employee Data:</h2>
            <div className="flex flex-wrap">
                {employeeData && employeeData.length > 0 && employeeData.map(employee => (
                    <div key={employee.id} className="bg-white shadow-md rounded-lg m-4 p-4 w-64">
                        <img src={`data:image/png;base64, ${employee.image}`} alt={employee.name} className="w-24 h-24 rounded-full mb-4" />
                        <div>
                            <h3 className="text-lg font-bold">{employee.name}</h3>
                            <p className="text-gray-600"><strong>Email:</strong> {employee.work_email}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EmployeeData;