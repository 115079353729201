// // // import React, { useEffect, useState } from 'react';
// // // import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, LineChart, Line } from 'recharts';
// // // import secureLocalStorage from 'react-secure-storage';

// // // const COLORS = ['#8884d8', '#82ca9d', '#ffc658'];

// // // const SummaryReportGraph = () => {
// // //   const [aggregatedData, setAggregatedData] = useState([]);
// // //   const [totalTeamPerformance, setTotalTeamPerformance] = useState({});
// // //   const [loading, setLoading] = useState(true);

// // //   // Fetch performance reports from API on component mount
// // //   useEffect(() => {
// // //     const fetchReports = async () => {
// // //       try {
// // //         const apiUrl = 'https://api.eth-prosperity.com/api/performance-report/';
// // //         const response = await fetch(apiUrl, {
// // //           headers: {
// // //             Authorization: `Token ${secureLocalStorage.getItem('token')}`, 
// // //           },
// // //         });
// // //         if (!response.ok) {
// // //           throw new Error('Failed to fetch reports');
// // //         }

// // //         const data = await response.json();

// // //         // Aggregate the data by user
// // //         const aggregated = data.reduce((acc, report) => {
// // //           const { username, fb_engagement, tiktok_engagement, twitter_engagement, fb_total_posts, tiktok_total_posts, twitter_total_posts, created_at } = report;

// // //           // If the username doesn't exist yet in the accumulator, create an entry
// // //           if (!acc[username]) {
// // //             acc[username] = {
// // //               username,
// // //               fb_engagement: 0,
// // //               tiktok_engagement: 0,
// // //               twitter_engagement: 0,
// // //               fb_total_posts: 0,
// // //               tiktok_total_posts: 0,
// // //               twitter_total_posts: 0,
// // //               created_at,
// // //             };
// // //           }

// // //           // Sum the values for each user
// // //           acc[username].fb_engagement += fb_engagement;
// // //           acc[username].tiktok_engagement += tiktok_engagement;
// // //           acc[username].twitter_engagement += twitter_engagement;
// // //           acc[username].fb_total_posts += fb_total_posts;
// // //           acc[username].tiktok_total_posts += tiktok_total_posts;
// // //           acc[username].twitter_total_posts += twitter_total_posts;

// // //           return acc;
// // //         }, {});

// // //         // Convert the aggregated object into an array
// // //         const aggregatedArray = Object.values(aggregated);
// // //         setAggregatedData(aggregatedArray);

// // //         // Calculate total team performance
// // //         const totalPerformance = aggregatedArray.reduce(
// // //           (acc, user) => {
// // //             acc.fb_engagement += user.fb_engagement;
// // //             acc.tiktok_engagement += user.tiktok_engagement;
// // //             acc.twitter_engagement += user.twitter_engagement;
// // //             acc.fb_total_posts += user.fb_total_posts;
// // //             acc.tiktok_total_posts += user.tiktok_total_posts;
// // //             acc.twitter_total_posts += user.twitter_total_posts;
// // //             return acc;
// // //           },
// // //           { fb_engagement: 0, tiktok_engagement: 0, twitter_engagement: 0, fb_total_posts: 0, tiktok_total_posts: 0, twitter_total_posts: 0 }
// // //         );

// // //         setTotalTeamPerformance(totalPerformance);
// // //       } catch (error) {
// // //         console.error('Error fetching reports:', error.message);
// // //       } finally {
// // //         setLoading(false);
// // //       }
// // //     };

// // //     fetchReports();
// // //   }, []);

// // //   return (
// // //     <div className="p-6 bg-white rounded-md shadow-lg">
// // //       <h2 className="text-2xl font-bold mb-4">Employee Performance Summary</h2>
// // //       {loading ? (
// // //         <div className="flex justify-center items-center">
// // //           <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"></div>
// // //         </div>
// // //       ) : (
// // //         <>
// // //           {/* Bar Chart for Individual Performance */}
// // //           <ResponsiveContainer width="100%" height={400}>
// // //             <BarChart
// // //               data={aggregatedData}
// // //               margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
// // //             >
// // //               <CartesianGrid strokeDasharray="3 3" />
// // //               <XAxis dataKey="username" />
// // //               <YAxis />
// // //               <Tooltip />
// // //               <Legend />
// // //               <Bar dataKey="fb_engagement" fill="#8884d8" />
// // //               <Bar dataKey="tiktok_engagement" fill="#82ca9d" />
// // //               <Bar dataKey="twitter_engagement" fill="#ffc658" />
// // //             </BarChart>
// // //           </ResponsiveContainer>

// // //           {/* Pie Chart for Facebook Engagement Distribution */}
// // //           <h3 className="text-xl font-bold mt-8">Facebook Engagement Distribution</h3>
// // //           <ResponsiveContainer width="100%" height={400}>
// // //             <PieChart>
// // //               <Pie
// // //                 data={aggregatedData}
// // //                 dataKey="fb_engagement"
// // //                 nameKey="username"
// // //                 cx="50%"
// // //                 cy="50%"
// // //                 outerRadius={150}
// // //                 fill="#8884d8"
// // //                 label
// // //               >
// // //                 {aggregatedData.map((entry, index) => (
// // //                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
// // //                 ))}
// // //               </Pie>
// // //               <Tooltip />
// // //             </PieChart>
// // //           </ResponsiveContainer>

// // //           {/* Line Chart for Posts Over Time */}
// // //           <h3 className="text-xl font-bold mt-8">Posts Over Time</h3>
// // //           <ResponsiveContainer width="100%" height={400}>
// // //             <LineChart
// // //               data={aggregatedData}
// // //               margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
// // //             >
// // //               <CartesianGrid strokeDasharray="3 3" />
// // //               <XAxis dataKey="created_at" />
// // //               <YAxis />
// // //               <Tooltip />
// // //               <Legend />
// // //               <Line type="monotone" dataKey="fb_total_posts" stroke="#8884d8" activeDot={{ r: 8 }} />
// // //               <Line type="monotone" dataKey="tiktok_total_posts" stroke="#82ca9d" />
// // //               <Line type="monotone" dataKey="twitter_total_posts" stroke="#ffc658" />
// // //             </LineChart>
// // //           </ResponsiveContainer>

// // //           {/* Total Team Performance */}
// // //           <h3 className="text-xl font-bold mt-8">Total Team Performance (Engagement)</h3>
// // //           <ResponsiveContainer width="100%" height={400}>
// // //             <BarChart
// // //               data={[
// // //                 {
// // //                   platform: 'Facebook',
// // //                   engagement: totalTeamPerformance.fb_engagement,
// // //                 },
// // //                 {
// // //                   platform: 'TikTok',
// // //                   engagement: totalTeamPerformance.tiktok_engagement,
// // //                 },
// // //                 {
// // //                   platform: 'Twitter',
// // //                   engagement: totalTeamPerformance.twitter_engagement,
// // //                 },
// // //               ]}
// // //               margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
// // //             >
// // //               <CartesianGrid strokeDasharray="3 3" />
// // //               <XAxis dataKey="platform" />
// // //               <YAxis />
// // //               <Tooltip />
// // //               <Legend />
// // //               <Bar dataKey="engagement" fill="#8884d8" />
// // //             </BarChart>
// // //           </ResponsiveContainer>

// // //           {/* Pie Chart for Total Team Posts */}
// // //           <h3 className="text-xl font-bold mt-8">Team Posts Distribution</h3>
// // //           <ResponsiveContainer width="100%" height={400}>
// // //             <PieChart>
// // //               <Pie
// // //                 data={[
// // //                   { platform: 'Facebook', posts: totalTeamPerformance.fb_total_posts },
// // //                   { platform: 'TikTok', posts: totalTeamPerformance.tiktok_total_posts },
// // //                   { platform: 'Twitter', posts: totalTeamPerformance.twitter_total_posts },
// // //                 ]}
// // //                 dataKey="posts"
// // //                 nameKey="platform"
// // //                 cx="50%"
// // //                 cy="50%"
// // //                 outerRadius={150}
// // //                 fill="#8884d8"
// // //                 label
// // //               >
// // //                 <Cell fill="#8884d8" />
// // //                 <Cell fill="#82ca9d" />
// // //                 <Cell fill="#ffc658" />
// // //               </Pie>
// // //               <Tooltip />
// // //             </PieChart>
// // //           </ResponsiveContainer>
// // //         </>
// // //       )}
// // //     </div>
// // //   );
// // // };

// // // export default SummaryReportGraph;

// // import React, { useEffect, useState } from 'react';
// // import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, LineChart, Line } from 'recharts';
// // import secureLocalStorage from 'react-secure-storage';

// // const COLORS = ['#8884d8', '#82ca9d', '#ffc658'];

// // const SummaryReportGraph = () => {
// //   const [aggregatedData, setAggregatedData] = useState([]);
// //   const [totalTeamPerformance, setTotalTeamPerformance] = useState({});
// //   const [loading, setLoading] = useState(true);

// //   useEffect(() => {
// //     const fetchReports = async () => {
// //       try {
// //         const apiUrl = 'https://api.eth-prosperity.com/api/performance-report/';
// //         const response = await fetch(apiUrl, {
// //           headers: {
// //             Authorization: `Token ${secureLocalStorage.getItem('token')}`,
// //           },
// //         });
// //         if (!response.ok) {
// //           throw new Error('Failed to fetch reports');
// //         }

// //         const data = await response.json();

// //         const aggregated = data.reduce((acc, report) => {
// //           const { username, fb_engagement, tiktok_engagement, twitter_engagement, fb_total_posts, tiktok_total_posts, twitter_total_posts, created_at } = report;

// //           if (!acc[username]) {
// //             acc[username] = {
// //               username,
// //               fb_engagement: 0,
// //               tiktok_engagement: 0,
// //               twitter_engagement: 0,
// //               fb_total_posts: 0,
// //               tiktok_total_posts: 0,
// //               twitter_total_posts: 0,
// //               created_at,
// //             };
// //           }

// //           acc[username].fb_engagement += fb_engagement;
// //           acc[username].tiktok_engagement += tiktok_engagement;
// //           acc[username].twitter_engagement += twitter_engagement;
// //           acc[username].fb_total_posts += fb_total_posts;
// //           acc[username].tiktok_total_posts += tiktok_total_posts;
// //           acc[username].twitter_total_posts += twitter_total_posts;

// //           return acc;
// //         }, {});

// //         const aggregatedArray = Object.values(aggregated);
// //         setAggregatedData(aggregatedArray);

// //         const totalPerformance = aggregatedArray.reduce(
// //           (acc, user) => {
// //             acc.fb_engagement += user.fb_engagement;
// //             acc.tiktok_engagement += user.tiktok_engagement;
// //             acc.twitter_engagement += user.twitter_engagement;
// //             acc.fb_total_posts += user.fb_total_posts;
// //             acc.tiktok_total_posts += user.tiktok_total_posts;
// //             acc.twitter_total_posts += user.twitter_total_posts;
// //             return acc;
// //           },
// //           { fb_engagement: 0, tiktok_engagement: 0, twitter_engagement: 0, fb_total_posts: 0, tiktok_total_posts: 0, twitter_total_posts: 0 }
// //         );

// //         setTotalTeamPerformance(totalPerformance);
// //       } catch (error) {
// //         console.error('Error fetching reports:', error.message);
// //       } finally {
// //         setLoading(false);
// //       }
// //     };

// //     fetchReports();
// //   }, []);

// //   return (
// //     <div className="p-6 bg-white rounded-md shadow-lg">
// //       <h2 className="text-2xl font-bold mb-4">Employee Performance Summary</h2>
// //       {loading ? (
// //         <div className="flex justify-center items-center">
// //           <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"></div>
// //         </div>
// //       ) : (
// //         <>
// //           <ResponsiveContainer width="100%" height={400}>
// //             <BarChart data={aggregatedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
// //               <CartesianGrid strokeDasharray="3 3" />
// //               <XAxis dataKey="username" />
// //               <YAxis />
// //               <Tooltip />
// //               <Legend />
// //               <Bar dataKey="fb_engagement" fill="#8884d8" />
// //               <Bar dataKey="tiktok_engagement" fill="#82ca9d" />
// //               <Bar dataKey="twitter_engagement" fill="#ffc658" />
// //             </BarChart>
// //           </ResponsiveContainer>
// //           <h3 className="text-xl font-bold mt-8">Facebook Engagement Distribution</h3>
// //           <ResponsiveContainer width="100%" height={400}>
// //             <PieChart>
// //               <Pie data={aggregatedData} dataKey="fb_engagement" nameKey="username" cx="50%" cy="50%" outerRadius={150} fill="#8884d8" label>
// //                 {aggregatedData.map((entry, index) => (
// //                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
// //                 ))}
// //               </Pie>
// //               <Tooltip />
// //             </PieChart>
// //           </ResponsiveContainer>
// //           <h3 className="text-xl font-bold mt-8">Posts Over Time</h3>
// //           <ResponsiveContainer width="100%" height={400}>
// //             <LineChart data={aggregatedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
// //               <CartesianGrid strokeDasharray="3 3" />
// //               <XAxis dataKey="created_at" />
// //               <YAxis />
// //               <Tooltip />
// //               <Legend />
// //               <Line type="monotone" dataKey="fb_total_posts" stroke="#8884d8" activeDot={{ r: 8 }} />
// //               <Line type="monotone" dataKey="tiktok_total_posts" stroke="#82ca9d" />
// //               <Line type="monotone" dataKey="twitter_total_posts" stroke="#ffc658" />
// //             </LineChart>
// //           </ResponsiveContainer>
// //           <h3 className="text-xl font-bold mt-8">Total Team Performance (Engagement)</h3>
// //           <ResponsiveContainer width="100%" height={400}>
// //             <BarChart data={[
// //               { platform: 'Facebook', engagement: totalTeamPerformance.fb_engagement },
// //               { platform: 'TikTok', engagement: totalTeamPerformance.tiktok_engagement },
// //               { platform: 'Twitter', engagement: totalTeamPerformance.twitter_engagement },
// //             ]} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
// //               <CartesianGrid strokeDasharray="3 3" />
// //               <XAxis dataKey="platform" />
// //               <YAxis />
// //               <Tooltip />
// //               <Legend />
// //               <Bar dataKey="engagement" fill="#8884d8" />
// //             </BarChart>
// //           </ResponsiveContainer>
// //           <h3 className="text-xl font-bold mt-8">Team Posts  Distribution</h3>
// //           <ResponsiveContainer width="100%" height={400}>
// //             <PieChart>
// //               <Pie data={[
// //                 { platform: 'Facebook', posts: totalTeamPerformance.fb_total_posts },
// //                 { platform: 'TikTok', posts: totalTeamPerformance.tiktok_total_posts },
// //                 { platform: 'Twitter', posts: totalTeamPerformance.twitter_total_posts },
// //               ]} dataKey="posts" nameKey="platform" cx="50%" cy="50%" outerRadius={150} fill="#8884d8" label>
// //                 <Cell fill="#8884d8" />
// //                 <Cell fill="#82ca9d" />
// //                 <Cell fill="#ffc658" />
// //               </Pie>
// //               <Tooltip />
// //             </PieChart>
// //           </ResponsiveContainer>
// //         </>
// //       )}
// //     </div>
// //   );
// // };

// // export default SummaryReportGraph;


// import React, { useEffect, useState } from 'react';
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, LineChart, Line } from 'recharts';
// import secureLocalStorage from 'react-secure-storage';

// const COLORS = ['#8884d8', '#82ca9d', '#ffc658'];

// const SummaryReportGraph = () => {
//   const [aggregatedData, setAggregatedData] = useState([]);
//   const [totalTeamPerformance, setTotalTeamPerformance] = useState({});
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchReports = async () => {
//       try {
//         const apiUrl = 'https://api.eth-prosperity.com/api/performance-report/';
//         const response = await fetch(apiUrl, {
//           headers: {
//             Authorization: `Token ${secureLocalStorage.getItem('token')}`,
//           },
//         });
//         if (!response.ok) {
//           throw new Error('Failed to fetch reports');
//         }

//         const data = await response.json();

//         const aggregated = data.reduce((acc, report) => {
//           const { username, fb_engagement, tiktok_engagement, twitter_engagement, fb_total_posts, tiktok_total_posts, twitter_total_posts, fb_new_followers, tiktok_new_followers, twitter_new_followers, tiktok_total_views, created_at } = report;

//           if (!acc[username]) {
//             acc[username] = {
//               username,
//               fb_engagement: 0,
//               tiktok_engagement: 0,
//               twitter_engagement: 0,
//               fb_total_posts: 0,
//               tiktok_total_posts: 0,
//               twitter_total_posts: 0,
//               fb_new_followers: 0,
//               tiktok_new_followers: 0,
//               twitter_new_followers: 0,
//               tiktok_total_views: 0,
//               created_at,
//             };
//           }

//           acc[username].fb_engagement += fb_engagement;
//           acc[username].tiktok_engagement += tiktok_engagement;
//           acc[username].twitter_engagement += twitter_engagement;
//           acc[username].fb_total_posts += fb_total_posts;
//           acc[username].tiktok_total_posts += tiktok_total_posts;
//           acc[username].twitter_total_posts += twitter_total_posts;
//           acc[username].fb_new_followers += fb_new_followers;
//           acc[username].tiktok_new_followers += tiktok_new_followers;
//           acc[username].twitter_new_followers += twitter_new_followers;
//           acc[username].tiktok_total_views += tiktok_total_views;

//           return acc;
//         }, {});

//         const aggregatedArray = Object.values(aggregated);
//         setAggregatedData(aggregatedArray);

//         const totalPerformance = aggregatedArray.reduce(
//           (acc, user) => {
//             acc.fb_engagement += user.fb_engagement;
//             acc.tiktok_engagement += user.tiktok_engagement;
//             acc.twitter_engagement += user.twitter_engagement;
//             acc.fb_total_posts += user.fb_total_posts;
//             acc.tiktok_total_posts += user.tiktok_total_posts;
//             acc.twitter_total_posts += user.twitter_total_posts;
//             acc.fb_new_followers += user.fb_new_followers;
//             acc.tiktok_new_followers += user.tiktok_new_followers;
//             acc.twitter_new_followers += user.twitter_new_followers;
//             acc.tiktok_total_views += user.tiktok_total_views;
//             return acc;
//           },
//           { fb_engagement: 0, tiktok_engagement: 0, twitter_engagement: 0, fb_total_posts: 0, tiktok_total_posts: 0, twitter_total_posts: 0, fb_new_followers: 0, tiktok_new_followers: 0, twitter_new_followers: 0, tiktok_total_views: 0 }
//         );

//         setTotalTeamPerformance(totalPerformance);
//       } catch (error) {
//         console.error('Error fetching reports:', error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchReports();
//   }, []);

//   return (
//     <div className="p-6 bg-white rounded-md shadow-lg">
//       <h2 className="text-2xl font-bold mb-4">Employee Performance Summary</h2>
//       {loading ? (
//         <div className="flex justify-center items-center">
//           <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"></div>
//         </div>
//       ) : (
//         <>
//           <ResponsiveContainer width="100%" height={400}>
//             <BarChart data={aggregatedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis dataKey="username" />
//               <YAxis />
//               <Tooltip />
//               <Legend />
//               <Bar dataKey="fb_engagement" fill="#8884d8" />
//               <Bar dataKey="tiktok_engagement" fill="#82ca9d" />
//               <Bar dataKey="twitter_engagement" fill="#ffc658" />
//               <Bar dataKey="fb_new_followers" fill="#00C49F" />
//               <Bar dataKey="tiktok_new_followers" fill="#FFBB28" />
//               <Bar dataKey="twitter_new_followers" fill="#FF8042" />
//               <Bar dataKey="tiktok_total_views" fill="#FF6347" />
//             </BarChart>
//           </ResponsiveContainer>
//           <h3 className="text-xl font-bold mt-8">Facebook Engagement Distribution</h3>
//           <ResponsiveContainer width="100%" height={400}>
//             <PieChart>
//               <Pie data={aggregatedData} dataKey="fb_engagement" nameKey="username" cx="50%" cy="50%" outerRadius={150} fill="#8884d8" label>
//                 {aggregatedData.map((entry, index) => (
//                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                 ))}
//               </Pie>
//               <Tooltip />
//             </PieChart>
//           </ResponsiveContainer>
//           <h3 className="text-xl font-bold mt-8">Posts Over Time</h3>
//           <ResponsiveContainer width="100%" height={400}>
//             <LineChart data={aggregatedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis dataKey="created_at" />
//               <YAxis />
//               <Tooltip />
//               <Legend />
//               <Line type="monotone" dataKey="fb_total_posts" stroke="#8884d8" activeDot={{ r: 8 }} />
//               <Line type="monotone" dataKey="tiktok_total_posts" stroke="#82ca9d" />
//               <Line type="monotone" dataKey="twitter_total_posts" stroke="#ffc658" />
//             </LineChart>
//           </ResponsiveContainer>
//           <h3 className="text-xl font-bold mt-8">Total Team Performance (Engagement and Followers)</h3>
//           <ResponsiveContainer width="100%" height={400}>
//             <BarChart data={[
//               { platform: 'Facebook Engagement', value: totalTeamPerformance.fb_engagement },
//               { platform: 'TikTok Engagement', value: totalTeamPerformance.tiktok_engagement },
//               { platform: 'Twitter Engagement', value: totalTeamPerformance.twitter_engagement },
//               { platform: 'Facebook Followers', value: totalTeamPerformance.fb_new_followers },
//               { platform: 'TikTok Followers', value: totalTeamPerformance.tiktok_new_followers },
//               { platform: 'Twitter Followers', value: totalTeamPerformance.twitter_new_followers },
//               { platform: 'TikTok Views', value: totalTeamPerformance.tiktok_total_views },
//             ]} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
//               <CartesianGrid strokeDasharray="3 3" />
//               <XAxis dataKey="platform" />
//               <YAxis />
//               <Tooltip />
//               <Legend />
//               <Bar dataKey="value" fill="#8884d8" />
//             </BarChart>
//           </ResponsiveContainer>
//         </>
//       )}
//     </div>
//   );
// };

// export default SummaryReportGraph;



import React, { useEffect, useState } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  PieChart, Pie, Cell, LineChart, Line
} from 'recharts';
import secureLocalStorage from 'react-secure-storage';

const COLORS = ['#8884d8', '#82ca9d', '#ffc658'];

const SummaryReportGraph = () => {
  const [aggregatedData, setAggregatedData] = useState([]);
  const [totalTeamPerformance, setTotalTeamPerformance] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const apiUrl = 'https://api.eth-prosperity.com/api/performance-report/';
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Token ${secureLocalStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch reports');
        }

        const data = await response.json();

        const aggregated = data.reduce((acc, report) => {
          const {
            username, fb_engagement, tiktok_engagement, twitter_engagement,
            fb_total_posts, tiktok_total_posts, twitter_total_posts,
            fb_new_followers, tiktok_new_followers, twitter_new_followers,
            tiktok_total_views, created_at
          } = report;

          if (!acc[username]) {
            acc[username] = {
              username,
              fb_engagement: 0,
              tiktok_engagement: 0,
              twitter_engagement: 0,
              fb_total_posts: 0,
              tiktok_total_posts: 0,
              twitter_total_posts: 0,
              fb_new_followers: 0,
              tiktok_new_followers: 0,
              twitter_new_followers: 0,
              tiktok_total_views: 0,
              created_at,
            };
          }

          acc[username].fb_engagement += fb_engagement;
          acc[username].tiktok_engagement += tiktok_engagement;
          acc[username].twitter_engagement += twitter_engagement;
          acc[username].fb_total_posts += fb_total_posts;
          acc[username].tiktok_total_posts += tiktok_total_posts;
          acc[username].twitter_total_posts += twitter_total_posts;
          acc[username].fb_new_followers += fb_new_followers;
          acc[username].tiktok_new_followers += tiktok_new_followers;
          acc[username].twitter_new_followers += twitter_new_followers;
          acc[username].tiktok_total_views += tiktok_total_views;

          return acc;
        }, {});

        const aggregatedArray = Object.values(aggregated);
        setAggregatedData(aggregatedArray);

        const totalPerformance = aggregatedArray.reduce(
          (acc, user) => {
            acc.fb_engagement += user.fb_engagement;
            acc.tiktok_engagement += user.tiktok_engagement;
            acc.twitter_engagement += user.twitter_engagement;
            acc.fb_total_posts += user.fb_total_posts;
            acc.tiktok_total_posts += user.tiktok_total_posts;
            acc.twitter_total_posts += user.twitter_total_posts;
            acc.fb_new_followers += user.fb_new_followers;
            acc.tiktok_new_followers += user.tiktok_new_followers;
            acc.twitter_new_followers += user.twitter_new_followers;
            acc.tiktok_total_views += user.tiktok_total_views;
            return acc;
          },
          { fb_engagement: 0, tiktok_engagement: 0, twitter_engagement: 0, fb_total_posts: 0, tiktok_total_posts: 0, twitter_total_posts: 0, fb_new_followers: 0, tiktok_new_followers: 0, twitter_new_followers: 0, tiktok_total_views: 0 }
        );

        setTotalTeamPerformance(totalPerformance);
      } catch (error) {
        console.error('Error fetching reports:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  return (
    <div className="p-6 bg-white rounded-md shadow-lg">
      <h2 className="text-2xl font-bold mb-4">Employee Performance Summary</h2>
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"></div>
        </div>
      ) : (
        <>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={aggregatedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="username" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="fb_engagement" fill="#8884d8" />
              <Bar dataKey="tiktok_engagement" fill="#82ca9d" />
              <Bar dataKey="twitter_engagement" fill="#ffc658" />
              <Bar dataKey="fb_new_followers" fill="#00C49F" />
              <Bar dataKey="tiktok_new_followers" fill="#FFBB28" />
              <Bar dataKey="twitter_new_followers" fill="#FF8042" />
              <Bar dataKey="tiktok_total_views" fill="#FF6347" />
            </BarChart>
          </ResponsiveContainer>
          <h3 className="text-xl font-bold mt-8">Facebook Engagement Distribution</h3>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie data={aggregatedData} dataKey="fb_engagement" nameKey="username" cx="50%" cy="50%" outerRadius={150} fill="#8884d8" label>
                {aggregatedData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          <h3 className="text-xl font-bold mt-8">Posts Over Time</h3>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={aggregatedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="created_at" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="fb_total_posts" stroke="#8884d8" activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="tiktok_total_posts" stroke="#82ca9d" />
              <Line type="monotone" dataKey="twitter_total_posts" stroke="#ffc658" />
            </LineChart>
          </ResponsiveContainer>
          <h3 className="text-xl font-bold mt-8">Total Team Performance (Engagement and Followers)</h3>
          {/* <ResponsiveContainer width="100%" height={400}>
            <BarChart data={[
              { platform: 'Facebook Engagement', value: totalTeamPerformance.fb_engagement },
              { platform: 'TikTok Engagement', value: totalTeamPerformance.tiktok_engagement },
              { platform: 'Twitter Engagement', value: totalTeamPerformance.twitter_engagement },
              { platform: 'Facebook Followers', value: totalTeamPerformance.fb_new_followers },
              { platform: 'TikTok Followers', value: totalTeamPerformance.tiktok_new_followers },
              { platform: 'Twitter Followers', value: totalTeamPerformance.twitter_new_followers },
              { platform: 'TikTok Views', value: totalTeamPerformance.tiktok_total_views },
            ]} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="platform" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer> */}

<ResponsiveContainer width="100%" height={400}>
  <BarChart 
    data={[
      { platform: 'Facebook Engagement', value: totalTeamPerformance.fb_engagement, fill: '#3b5998' }, // Facebook blue
      { platform: 'TikTok Engagement', value: totalTeamPerformance.tiktok_engagement, fill: '#ff4500' }, // Orange for TikTok
      { platform: 'Twitter Engagement', value: totalTeamPerformance.twitter_engagement, fill: '#1da1f2' }, // Twitter blue
      { platform: 'Facebook Followers', value: totalTeamPerformance.fb_new_followers, fill: '#4267B2' }, // Dark Facebook blue
      { platform: 'TikTok Followers', value: totalTeamPerformance.tiktok_new_followers, fill: '#ff8500' }, // Lighter orange for TikTok followers
      { platform: 'Twitter Followers', value: totalTeamPerformance.twitter_new_followers, fill: '#00acee' }, // Light Twitter blue
      { platform: 'TikTok Views', value: totalTeamPerformance.tiktok_total_views, fill: '#ff6347' }, // Coral for TikTok views
    ]}
    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="platform" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Bar dataKey="value" nameKey="platform" fill={({ payload }) => payload.fill} />
  </BarChart>
</ResponsiveContainer>

          {/* <h3 className="text-xl font-bold mt-8">Total Team Performance Distribution</h3> */}
          {/* <ResponsiveContainer width="100%" height={400}>
            <PieChart>

<Pie
  data={[
    { name: 'Facebook Engagement', value: totalTeamPerformance.fb_engagement },
    { name: 'TikTok Engagement', value: totalTeamPerformance.tiktok_engagement },
    { name: 'Twitter Engagement', value: totalTeamPerformance.twitter_engagement },
    { name: 'Facebook Followers', value: totalTeamPerformance.fb_new_followers },
    { name: 'TikTok Followers', value: totalTeamPerformance.tiktok_new_followers },
    { name: 'Twitter Followers', value: totalTeamPerformance.twitter_new_followers },
    { name: 'TikTok Views', value: totalTeamPerformance.tiktok_total_views },
  ]}
  cx="50%"
  cy="50%"
  outerRadius={150}
  fill="#8884d8"
  label
>
  {[
    { name: 'Facebook Engagement', value: totalTeamPerformance.fb_engagement },
    { name: 'TikTok Engagement', value: totalTeamPerformance.tiktok_engagement },
    { name: 'Twitter Engagement', value: totalTeamPerformance.twitter_engagement },
    { name: 'Facebook Followers', value: totalTeamPerformance.fb_new_followers },
    { name: 'TikTok Followers', value: totalTeamPerformance.tiktok_new_followers },
    { name: 'Twitter Followers', value: totalTeamPerformance.twitter_new_followers },
    { name: 'TikTok Views', value: totalTeamPerformance.tiktok_total_views },
  ].map((entry, index) => (
    <Cell 
      key={`cell-${index}`} 
      fill={index === 1 || index === 4 || index === 6 ? '#E36E1C' : COLORS[index % COLORS.length]} 
    />
  ))}
</Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer> */}

          <h3 className="text-xl font-bold mt-8">Total New Followers Over Time</h3>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={aggregatedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="created_at" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="fb_new_followers" stroke="#8884d8" />
              <Line type="monotone" dataKey="tiktok_new_followers" stroke="#82ca9d" />
              <Line type="monotone" dataKey="twitter_new_followers" stroke="#ffc658" />
            </LineChart>
          </ResponsiveContainer>
        </>
      )}
    </div>
  );
};

export default SummaryReportGraph;